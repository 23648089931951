import { ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { useRouter } from "vue-router";
export default function useTitles() {
  const title = ref();
  const router = useRouter();

  const getTitles = async (page) => {
    return await ApiService.query("titles",page);
  };

  const getTitle = async (id) => {
    let response = await ApiService.get("titles/" + id);

    title.value = response.data.data;
  };

  const destroyTitle = async (id) => {
    await ApiService.delete("titles/" + id);
  };

  const stoerTitle = async (data) => {
    await ApiService.post("titles", data);
    await router.push({ name: "apps.appeals.titles.index" });
  };

  const updateTitle = async (data) => {
    await ApiService.put("titles/" + data.id, data);
    await router.push({
      name: "apps.appeals.titles.edit",
      params: { id: data.id },
    });
  };

  return {
    getTitles,
    getTitle,
    title,
    destroyTitle,
    stoerTitle,
    updateTitle,
  };
}
